<!--
 Copyright 2023, Avation Medical. All rights reserved.
 
 This code is proprietary and confidential information of Avation Medical. Any use, reproduction, modification
 or distribution of the code without the express prior written consent of Avation Medical is strictly prohibited.
-->

<div class="login-page" *ngIf="displayPage">
    <div class="login-header">
        <img src="/assets/icons/ic_vivally_logo.svg"/>
    </div>

    <div class="login-body">
        <div class="login-block horizontal">
            <a class="qr-block" [href]="link_android">
                <p>Install Android</p>
                <img class="qr-code" src="/assets/qr-codes/android.png"/>
            </a>
            <a class="qr-block" [href]="link_iOS">
                <p>Install iOS</p>
                <img class="qr-code" src="/assets/qr-codes/ios.png"/>
            </a>
        </div>
    </div>

    <div class="login-footer">
        <div>
            <a href="https://avation.com/legal/#terms">Terms of Use</a> and <a href="https://avation.com/legal/#privacy">Privacy Policy</a>
        </div>
        <div class="copyright">
            Vivally™ Cloud, VCG-WPN01, 2.0.0.32-staging, Copyright 2022 © Avation Medical
        </div>
    </div>
</div>