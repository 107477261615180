<div class="top-conatiner">
    <button class="push-button back-button tertiary warrenty-back-btn back-btn-position" [routerLink]="['..']"><mat-icon
            svgIcon="back"></mat-icon>
    </button>
</div>
<div class="delete-content">
    <img src="/assets/icons/ic_vivally_logo.svg" height="61px" width="auto" style="margin-bottom: 1.5em;" />
    <h1 class="request-header">Request a Patient Vivally Account</h1>
    <p class="message">To create a Vivally account, please fill out the form below and our Customer Care team will
        review your
        request for approval.</p>
    <form [formGroup]="form" class="form-outline" (ngSubmit)="onSubmit()">
        <!-- First Name -->
        <div class="form-box form">
            <mat-label>First Name<span style="color: #D94123;"> *</span></mat-label>
            <mat-form-field>
                <input matInput formControlName="firstName" type="text" placeholder="e.g. John" />
                <mat-error><app-form-input-error [control]="form.get('firstName')"></app-form-input-error></mat-error>
            </mat-form-field>
        </div>

        <!-- Last Name -->
        <div class="form-box">
            <mat-label>Last Name<span style="color: #D94123;"> *</span></mat-label>
            <mat-form-field>
                <input matInput formControlName="lastName" type="text" placeholder="e.g. Doe" />
                <mat-error><app-form-input-error [control]="form.get('lastName')"></app-form-input-error></mat-error>
            </mat-form-field>
        </div>

        <!-- Email -->
        <div class="form-box">
            <mat-label>Email<span style="color: #D94123;"> *</span></mat-label>
            <mat-form-field>
                <input matInput formControlName="email" type="email" placeholder="e.g. john@example.com" />
                <mat-error><app-form-input-error [control]="form.get('email')"></app-form-input-error></mat-error>
            </mat-form-field>
        </div>

        <!-- Confirmation Email -->
        <div class="form-box">
            <mat-label>Confirm Email<span style="color: #D94123;"> *</span></mat-label>
            <mat-form-field>
                <input matInput formControlName="confirmationEmail" type="email" placeholder="Re-enter your email" />
                <mat-error *ngIf="form.get('confirmationEmail').hasError('emailsNotSame') && 
                (form.get('email').touched || form.get('confirmationEmail').touched)">
                    Emails do not match
                </mat-error>
                <mat-error>
                    <app-form-input-error [control]="form.get('confirmationEmail')"></app-form-input-error>
                </mat-error>
            </mat-form-field>
        </div>

        <!-- Phone -->
        <div class="form-box">
            <mat-label>Phone Number<span style="color: #D94123;"> *</span></mat-label>
            <mat-form-field>
                <phone-number-input matInput formControlName="phone" placeholder="Phone number" ext="false"></phone-number-input>
                <mat-error><app-form-input-error [control]="form.get('phone')"></app-form-input-error></mat-error>
            </mat-form-field>
        </div>
        
        <!-- Checkbox -->
        <div class="model-form-row">
            <div class="form-box">
                <mat-checkbox formControlName="isPatientCheckbox" color="primary">I am a Patient<span style="color: #D94123;"> *</span>
                </mat-checkbox>
            </div>
        </div>
        
        <!-- Submit Button -->
        <div class="button-container">
            <button type="submit" class="primary-submit" [disabled]="!form.valid">Submit</button>
        </div>
        <p style="color: #D94123;text-align: center;margin-top: 1em;" *ngIf="errorMessage">{{errorMessage}}</p>
    </form>
    <br>
    <!-- <div class="message" style="font-weight: 500;" *ngIf="successMessage">
        Your request has been submitted. You will receive an email when your request has been approved.
    </div> -->
    <br><br><br>
    <div class="message" style="margin-bottom: 25px;">
        * If you have a Vivally account, and you wish to delete it, please contact our Customer Care team at
        <a href="mailto:customercare@avation.com">customercare@avation.com</a>.
    </div>
</div>
<vivally-footer [showLinks]="false"></vivally-footer>
