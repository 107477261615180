<!--
 Copyright 2023, Avation Medical. All rights reserved.
 
 This code is proprietary and confidential information of Avation Medical. Any use, reproduction, modification
 or distribution of the code without the express prior written consent of Avation Medical is strictly prohibited.
-->

<mat-toolbar class="navbar">
  <button [ngStyle]="{padding: showBurgerMenuStyle}" class="hamburger-menu" (click)="toggleSidebar()">
    <mat-icon *ngIf="showBurgerMenu">{{UserSettingsService.navToggle}}</mat-icon>
  </button>
  <div class="logo">
    <a [routerLink]="['/welcome']">
      <img src="/assets/icons/ic_vivally_logo.svg" />
    </a>
  </div>
  <div class="header-text" id="pageTitle">{{ pageTitle }}</div>
  <div *ngIf="!showUserMenu"></div>
  <div *ngIf="showUserMenu">
    <div style="float: right; padding: 0!important;margin-top: 10px;">
      <button id="notificationButton" mat-button class="menu-button" matTooltip="Send Push Notification" (click)="openNotificationDialog()" *ngIf="displayNotification()">
        <mat-icon class="navbar-icon" svgIcon="push"></mat-icon>
      </button>
      <button id="exportButton" mat-button class="menu-button" matTooltip="Export Data to CSVF" (click)="export()" *ngIf="displayExport()">
        <mat-icon class="navbar-icon" svgIcon="export"></mat-icon>
      </button>
      <button id="reportButton" mat-button class="menu-button" matTooltip="Report" (click)="openReportDialog()" *ngIf="displayReport()">
        <mat-icon class="navbar-icon" svgIcon="analytics"></mat-icon>
      </button>
      <button id="helpButton" mat-button class="menu-button" matTooltip="Help" (click)="openHelpDialog()" *ngIf="displayHelp()">
        <mat-icon class="navbar-icon" svgIcon="help"></mat-icon>
      </button>
      <button id="accountButton" mat-button class="menu-button"  matTooltip="Account" style="display: inline-block; padding: 0" [matMenuTriggerFor]="belowMenu">
        <mat-icon class="navbar-icon" svgIcon="account"></mat-icon>
      </button>
      <mat-menu #belowMenu="matMenu" yPosition="below">
        <button id="account_circle" mat-menu-item>
          <mat-icon>account_circle</mat-icon><ng-container *ngIf="user$ | async as user">{{user.givenName}} {{user.familyName}}</ng-container>
        </button>
        <button id="image_aspect_ratio" mat-menu-item [routerLink]="['/login/change-password']">
          <mat-icon>image_aspect_ratio</mat-icon>Change Password
        </button>
        <button id="logout" mat-menu-item (click)="logout()">
          <mat-icon>exit_to_app</mat-icon>Sign out
        </button>
      </mat-menu>
    </div>
  </div>
</mat-toolbar>