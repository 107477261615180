// Copyright 2023, Avation Medical. All rights reserved.
// 
// This code is proprietary and confidential information of Avation Medical. Any use, reproduction, modification
// or distribution of the code without the express prior written consent of Avation Medical is strictly prohibited.

import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class IconService {
  iconUrl: string = '../../../assets/icons/';
  icons = {
    'account': 'ic_account.svg',
    'admin': 'ic_admin.svg',
    'analytics': 'ic_analytics.svg',
    'back': 'chevron-left-solid.svg',
    'box': 'ic_box.svg',
    'box-active': 'ic_box_active.svg',
    'cart': 'ic_cart.svg',
    'check': 'ic_check.svg',
    'clinics': 'ic_clinics.svg',
    'coffee': 'ic_coffee.svg',
    'cup': 'ic_cup.svg',
    'export': 'ic_export.svg',
    'export_lite': 'ic_export_lite.svg',
    'facilities': 'ic_facilities.svg',
    'filters': 'ic_filters.svg',
    'help': 'ic_help.svg',
    'home': 'ic_home.svg',
    'journal': 'ic_journal.svg',
    'leaks': 'ic_leaks.svg',
    'left-arrow': 'ic_left-arrow.svg',
    'minus': 'ic_minus.svg',
    'minus-button': 'ic_minus_button.svg',
    'ota': 'ic_ota.svg',
    'patient': 'ic_patient.svg',
    'plus': 'ic_plus.svg',
    'plus-button': 'ic_plus_button.svg',
    'push': 'ic_push.svg',
    'restroom': 'ic_restroom.svg',
    'right-arrow': 'ic_right-arrow.svg',
    'studies': 'ic_studies.svg',
    'up-arrow': 'ic_up-arrow.svg',
    'urge': 'ic_urge.svg',
    'users': 'ic_users.svg',
    'vivally': 'ic_vivally.svg',
    'vivally-light': 'ic_vivally_2.svg',
    'vivally-active': 'ic_vivally_active.svg',
    'wine': 'ic_wine.svg',
    'delete':'ic_delete.svg',
    'no-address':'ic_no-address.svg',
    'emi':'ic_emi.svg',
    'download':'ic_download.svg',
    'info':'ic_info.svg',
    'credit-card':'ic_credit-card.svg',
    'circle-plus':'ic_circle-plus.svg',
    'saved_cards':'ic_saved_cards.svg',
    'xmark-white':'ic_xmark_white.svg',
    'confirmation-hook':'ic_confirmation_hook.svg',
    'bag':'ic_bag.svg',
    'bin':'ic_bin.svg',
    'request':'ic_request.svg',
    'circle-check':'ic_circle_check.svg',
    'circle-check-disabled':'ic_circle_check_disabled.svg',
    'xmark_red':'ic_circle_xmark_red.svg',
    'xmark-disabled':'ic_circle_xmark_disabled.svg',
  }

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) { }

  public registerIcons(): void {
    Object.entries(this.icons).forEach(([key, fileName]) => {
      this.matIconRegistry.addSvgIcon(
        key,
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          this.iconUrl + fileName
        )
      );
    });
  }
}
