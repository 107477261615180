// Copyright 2023, Avation Medical. All rights reserved.
// 
// This code is proprietary and confidential information of Avation Medical. Any use, reproduction, modification
// or distribution of the code without the express prior written consent of Avation Medical is strictly prohibited.

import { AppSettingsService } from 'src/app/app-settings/app-settings.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { observable, Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { PagedResult } from 'src/app/shared/models/paged-result.interface';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(private http: HttpClient, private readonly appSettingsService: AppSettingsService) { }

  public getProducts(userId) {
    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => {

        let params = {
          userId: userId
        }
        return this.http.post(`${appSettings.apiBaseUrl}/v1/squareup/catalog/search-catalog-items`, params)
      }
      )
    )
  }

  public getItemImage(imageId: String) {
    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.get(`${appSettings.apiBaseUrl}/v1/squareup/catalog/object/${imageId}`))
    );
  }

  public getOrder(orderIdId: String) {
    // return this.http.get(`http://localhost:9000/order/${orderIdId}`);
    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.get(`${appSettings.apiBaseUrl}/v1/squareup/user/order/${orderIdId}`))
    );
  }

  public applyDiscount(code, userId) {
    // return this.http.post(`http://localhost:9000/code/`,payload);
    let params = {
      user_id: userId,
      filter: {
        discount_code: code
      }
    }
    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.post(`${appSettings.apiBaseUrl}/v1/squareup/discount-codes/search`, params))
    );
  }
  public redeem(discount_code_id: string, orderId, userId) {
    // return this.http.post(`http://localhost:9000/redeem/`,payload);
    let params = {
      user_id: userId,
      discount_code_id: discount_code_id,
      order_id: orderId
    }
    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.post(`${appSettings.apiBaseUrl}/v1/squareup/discount/redeem`, params))
    );
  }

  public placeOrder(payload: any) {
    let body = {
      order: {
        line_items: payload.line_items,
      },
      userId: payload.userId,
    }
    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.post(`${appSettings.apiBaseUrl}/v1/squareup/user/order`, body))
    );

  }

  public sendEmail(userId, billingAddressId, shippingAddressId, orderId, paymentMethod,isChanged,draftPaymentMode){
    let body = {
      userId: userId,
      billingAddressId: billingAddressId,
      shippingAddressId: shippingAddressId,
      orderId: orderId,
      paymentMode: paymentMethod,
      isChanged: isChanged,
      draftPaymentMode: draftPaymentMode,
    }
    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.post(`${appSettings.apiBaseUrl}/v1/squareup/user/order/confirm`, body))
    );
  }

  public payment(body: any) {
    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.post(`${appSettings.apiBaseUrl}/v1/squareup/users/order/payment`, body))
    );
  }

  public paySubscription(body: any) {
    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.post(`${appSettings.apiBaseUrl}/v1/squareup/user/subscription/fullpayment`, body))
    );
  }
  

  public payInInstallments(body: any) {
    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.post(`${appSettings.apiBaseUrl}/v1/squareup/users/order/invoice`, body))
    );
  }

  public storeCard(body: any) {
    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.post(`${appSettings.apiBaseUrl}/v1/squareup/cards`, body))
    );
  }
  public deleteCard(cardId,userId) {
    let params: any = {}
    if (!!userId) {
      params.userId = userId
    }
    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.delete(`${appSettings.apiBaseUrl}/v1/squareup/cards/${cardId}/disable`,{params}))
    );
  }

  public getCard(userId: number,cardId:string,status:string) {
    let params: any = {}
    if (!!userId) {
      params.userId = userId
    }
    if (!!cardId) {
      params.cardId = cardId
    }
    if (!!status) {
      params.status = status
    }
    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.get<PagedResult<any>>(`${appSettings.apiBaseUrl}/v1/squareup/cards`,{params}))
    );
  }

  public getUserOrderHistory(userId, fromDate, status) {
    // return this.http.get(`http://localhost:9000/order-history/${userId}`);
    let params: any = {}
    if (!!userId) {
      params.userIds = userId
    }
    if (!!fromDate) {
      params.fromDate = fromDate
    }

    let tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1)
    params.toDate=tomorrow.toISOString()
    if (!!status) {
      params.status = status.toString()
    }

    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.get<PagedResult<any>>(`${appSettings.apiBaseUrl}/v1/squareup/user/order/get-details`, { params }))
    );
  }


  public updateOrder(fulfillments, orderId) {
    // return this.http.post(`http://localhost:9000/updateOrder/${orderId}`,fulfillments);
    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.put(`${appSettings.apiBaseUrl}/v1/squareup/users/order/fulfillments/${orderId}`, fulfillments))
    );

  }

  public getReceipt(paymentId, userId) {
    // return this.http.get(`http://localhost:9000/getReceit/${id}`);
    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.get(`${appSettings.apiBaseUrl}/v1/squareup/users/order/payment/${paymentId}?userId=${userId}`))
    );
  }



  public getOrders(apiQuery, orderStatus, purchaseOrderNumber, fromDate, toDate, patientId, firstName, lastName, orderId, notCreatedBy, userIds, isTestPatient?:boolean) {
    // return this.http.get(`http://localhost:9000/orders`);
    let params: any = {}
    if (!!purchaseOrderNumber) {
      params.PurchaseOrderNumber = purchaseOrderNumber
    }

    if (!!toDate) {
      params.toDate = toDate
    }
    if (!!fromDate) {
      params.fromDate = fromDate
    }
    if (!!patientId) {
      params.patientId = patientId
    }
    if (!!firstName) {
      params.firstName = firstName
    }
    if (!!lastName) {
      params.lastName = lastName
    }
    if (!!orderStatus) {
      params.status = orderStatus.toString()
    }
    if (!!orderId) {
      params.squareOrderId = orderId
    }
    if (!!notCreatedBy) {
      params.notCreatedBy = notCreatedBy
    }
    if (!!userIds) {
      params.userIds = userIds
    }

    if(isTestPatient === true || isTestPatient === false) {
      params.isTestPatient = isTestPatient;
    }
    
    if (apiQuery) {
      params = Object.assign({}, apiQuery, params);
    }

    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.get<PagedResult<any>>(`${appSettings.apiBaseUrl}/v1/squareup/user/order/get-details`, { params }))
    );
  }

  public createShipment(payload) {
    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => {
        payload.address_from = appSettings.addressFrom
        return this.http.post(`${appSettings.apiBaseUrl}/v1/shippo/shipment`, payload)
      }
      )
    );
  }



  public createLabel(rateId, orderId, shipmentId, userId) {
    let payload = {
      rate: rateId,
      user_id: userId,
      order_id: orderId,
      shipment_id: shipmentId
    }
    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.post(`${appSettings.apiBaseUrl}/v1/shippo/transaction`, payload))
    );
  }

  public createDiscountInOneCall(batches, formValue, userId) {
    // return this.http.post(`http://localhost:9000/createDiscountInOneCall`,payload);
    let payload = {
      userId: userId,
      batches: batches,
      discount_code: {
        code: formValue.code,
        max_redemptions: formValue.MRedemptions,
        expires_at: new Date(formValue.expiry).toISOString()
      }
    }
    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.post(`${appSettings.apiBaseUrl}/v1/squareup/create/discount-codes`, payload))
    );
  }

  public createCustomer(userId, givenName, email, phoneNumber, familyName) {
    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => {
        let params = {
          user_id: userId,
          given_name: givenName,
          email_address: email,
          phone_number: phoneNumber,
          family_name: familyName
        }
        return this.http.post(`${appSettings.apiBaseUrl}/v1/squareup/customer/create`, params)
      }
      )
    )



  }
  public updateOrderWithDiscountCode(userId, line_items, orderId) {
    let payload = {
      user_id: userId,
      order: {
        line_items: line_items
      }
    }
    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.put(`${appSettings.apiBaseUrl}/v1/squareup/users/order/discounts/${orderId}`, payload))
    );
  }

  public discountListing(apiQuery,userIds) {
    let params: any = {}
    if (apiQuery) {
      params = Object.assign({}, apiQuery, params);
    }
    if (userIds) {
      params.userIds = String(userIds);
    }
    
    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.get<PagedResult<any>>(`${appSettings.apiBaseUrl}/v1/squareup/discount-codes/get-details`,{params}))
    );

  }

  getInstallmentConfigurations(apiQuery, catalog_object_id, discount_code) {
    let params: any = {}
    params.status = 'active'
    if (apiQuery) {
      params = Object.assign({}, apiQuery, params);
    }
    if (!!catalog_object_id) {
      params.catalog_object_id = catalog_object_id
    }
    if (!!discount_code) {
      params.discount_code = discount_code
    }
    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.get<PagedResult<any>>(`${appSettings.apiBaseUrl}/v1/squareup/users/item/installment/details`, { params }))
    );
  }
  public postRecipientAddress(userId, address) {
    let payload = {
      UserId: userId,
      Address: address

    }
    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.post(`${appSettings.apiBaseUrl}/v1/shippo/shipment/recepient-address`, payload))
    );

  }
  loadRecipientAddresses(id,userId, type,status) {
    let params: any = {};
    if (!!id) {
      params.addressId = id
    }
    if (!!userId) {
      params.userId = userId;
    }
    if (!!type) {
      params.addressType = type
    }
    if (!!status) {
      params.status = status
    }

    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.get(`${appSettings.apiBaseUrl}/v1/shippo/shipment/recepient-address`, { params }))
    );
  }


  getSubscriptionConfigurations(apiQuery, ItemId, discountCodeId) {
    let params: any = {}
    if (apiQuery) {
      params = Object.assign({}, apiQuery, params);
    }
    if (!!ItemId) {
      params.ItemId = ItemId
    }
    if (!!discountCodeId) {
      params.discountCodeId = discountCodeId
    }
    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.get<PagedResult<any>>(`${appSettings.apiBaseUrl}/v1/squareup/item/subscription/config?Status=active`, { params }))
    );

  }

  getSubscriptions(apiQuery, orderId, patientId, userId) {
    let params: any = {}
    if (apiQuery) {
      params = Object.assign({}, apiQuery, params);
    }
    if (!!orderId) {
      params.PurchaseOrderNumber = orderId
    }
    if (!!patientId) {
      params.patientId = patientId
    }
    if (!!userId) {
      params.UserId = userId
    }
    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.get<PagedResult<any>>(`${appSettings.apiBaseUrl}/v1/squareup/user/subscription/summary`, { params }))
    );

  }
  updateSubscriptionSumary(id, userId) {
    let params = { subscriptionId: id, userId: userId }
    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.get(`${appSettings.apiBaseUrl}/v1/squareup/user/subscription/metadata`, { params }))
    );
  }

  changeOrderStatus(status, orderId, userId) {
    let payload = {
      user_id: userId,
      order_id: orderId,
      order_status: status
    }
    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.put(`${appSettings.apiBaseUrl}/v1/squareup/users/order/status`, payload))
    );
  }

  createInstallmentConfig(formValue) {

    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.post(`${appSettings.apiBaseUrl}/v1/squareup/users/item/installment/details`, formValue))
    );
  }

  createSubscriptionConfig(formValue) {

    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.post(`${appSettings.apiBaseUrl}/v1/squareup/item/subscription/config`, formValue))
    );
  }

  createSubscriptionPlan(payload) {
    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.post(`${appSettings.apiBaseUrl}/v1/squareup/catalog/object/subscription/variation`, payload))
    );
  }
  createSubscription(payload) {
    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.post(`${appSettings.apiBaseUrl}/v1/squareup/user/subscription`, payload))
    );
  }
  deleteSubscriptionConfig(id) {
    let payload = {
      Status: "inactive"
    }
    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.put(`${appSettings.apiBaseUrl}/v1/squareup/item/subscription/config?Id=${id}`, payload))
    );
  }

  deleteAddress(userId, addressId) {
    let params = { userId: userId, addressId: addressId }
    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.delete(`${appSettings.apiBaseUrl}/v1/shippo/shipment/recepient-address`, { params }))
    );
  }
  getSubscription(subscriptionId, userId) {
    let params: any = {}
    if (!!userId) {
      params.UserId = userId
    }
    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.get(`${appSettings.apiBaseUrl}/v1/squareup/user/subscription/${subscriptionId}`, { params }))
    );
  }

  getInvoice(invoiceId, userId) {
    let params = { userId: userId }
    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.get(`${appSettings.apiBaseUrl}/v1/squareup/users/order/invoice/${invoiceId}`, { params }))
    );

  }

  async downloadPdf(squareOrderId, orderId, userId) {
    let buffer: ArrayBuffer;

    buffer = await this.getPdf(squareOrderId, userId).pipe(take(1)).toPromise();

    // https://stackoverflow.com/questions/52154874/angular-6-downloading-file-from-rest-api         

    var pdfBlob = new Blob([buffer], { type: "application/pdf" });

    const data = window.URL.createObjectURL(pdfBlob);

    let link = document.createElement('a');
    link.href = data;
    link.download = `Order Confirmation-${orderId}.pdf`;
    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

    setTimeout(function () {
      window.URL.revokeObjectURL(data);
      link.remove();
    }, 100);
  }

  getPdf(orderId, userId): Observable<ArrayBuffer> {
    let params = new HttpParams();

    if (orderId) {
      params = params.append('orderId', orderId);
    }
    if (userId) {
      params = params.append('userId', userId);
    }

    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.get(`${appSettings.apiBaseUrl}/v1/squareup/pdf/purchaseorderconfirmation`, { params, responseType: 'arraybuffer' }))
    );
  }

  deleteInstallmentConfig(configId) {
    let payload = {
      status: "inactive"
    }
    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.put(`${appSettings.apiBaseUrl}/v1/squareup/users/item/installment/details?Id=${configId}`, payload))
    );
  }

  getSubitemConfiguration(apiQuery, CatalogObjectId, Status, ParentId, GroupId, id) {
    let params: any = {}
    if (apiQuery) {
      params = Object.assign({}, apiQuery, params);
    }
    if (!!CatalogObjectId) {
      params.CatalogObjectId = CatalogObjectId
    }
    if (!!Status) {
      params.Status = Status
    }
    if (!!ParentId) {
      params.ParentId = ParentId
    }
    if (!!GroupId) {
      params.GroupId = GroupId
    }
    if (!!id) {
      params.id = id
    }
    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.get<PagedResult<any>>(`${appSettings.apiBaseUrl}/v1/squareup/catalogitem/invoice/retrieve`, { params }))
    );

  }

  createSubitemConfig(payload) {
    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.post(`${appSettings.apiBaseUrl}/v1/squareup/catalogitem/invoice/create`, payload))
    );
  }
  getFullPaymentInvoice(orderId, userId): Observable<ArrayBuffer> {
    let params = new HttpParams();
    if (orderId) {
      params = params.append('orderId', orderId);
    }

    if (userId) {
      params = params.append('userId', userId);
    }


    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.get(`${appSettings.apiBaseUrl}/v1/squareup/pdf/invoice/fullpayment`, { params, responseType: 'arraybuffer' }))
    );
  }
  getSubscriptionInvoice(subscriptionId, invoiceId, userId): Observable<ArrayBuffer> {
    let params = new HttpParams();

    if (subscriptionId) {
      params = params.append('subscriptionId', subscriptionId);
    }
    if (invoiceId) {
      params = params.append('invoiceId', invoiceId);
    }

    if (userId) {
      params = params.append('userId', userId);
    }


    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.get(`${appSettings.apiBaseUrl}/v1/squareup/pdf/invoice/subscription`, { params, responseType: 'arraybuffer' }))
    );
  }
  async downloadInvoicePdf(subscriptionId, invoiceId, userId, orderId, paymentType,systemId) {
    let buffer: ArrayBuffer;
    if (paymentType == 'full_payment') {
      buffer = await this.getFullPaymentInvoice(orderId, userId).pipe(take(1)).toPromise();
    } else {
      buffer = await this.getSubscriptionInvoice(subscriptionId, invoiceId, userId).pipe(take(1)).toPromise();

    }

    var pdfBlob = new Blob([buffer], { type: "application/pdf" });

    const data = window.URL.createObjectURL(pdfBlob);

    let link = document.createElement('a');
    link.href = data;
    link.download = `Invoice-${systemId ? systemId : ''}.pdf`;
    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

    setTimeout(function () {
      window.URL.revokeObjectURL(data);
      link.remove();
    }, 100);
  }

  updateSubscription(userId,subscriptionId,cardId){
    let payload:any={}
    if(!!userId){
      payload.userId=userId
    }
    if(!!subscriptionId){
      payload.subscriptionId=subscriptionId
    }
    if(!!cardId){
      payload.cardId=cardId
    }
    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.post<PagedResult<any>>(`${appSettings.apiBaseUrl}/v1/squareup/user/subscription/update`, payload ))
    );
  }
  cancelSubscription(subscriptionId){
    let payload={
      subscriptionId:subscriptionId
    }
    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.post(`${appSettings.apiBaseUrl}/v1/squareup/user/subscription/cancel`, payload ))
    );
  }
  returnRequest(payload){
    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.post(`${appSettings.apiBaseUrl}/v1/squareup/user/order/return/request`, payload ))
    );
  }
  public getReturnRequests(apiQuery,orderId,status) {
    let params: any = {}
    if (!!orderId) {
      params.orderId = orderId
    }
    if (!!status) {
      params.status = status
    }
    if (apiQuery) {
      params = Object.assign({}, apiQuery, params);
    }

    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.get<PagedResult<any>>(`${appSettings.apiBaseUrl}/v1/squareup/user/order/return/request`, { params }))
    );
  }
}